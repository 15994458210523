<template>
  <ItemWrapper>
    <v-row justify-start wrap>
      <v-col cols="12">
        <AppCard
          :prev-route="prevRoute"
          :loading="pending"
          :title="`${$t('Subscription')} ${!_.isNull(subscription.name) ? subscription.name : ''}`"
        >
          <template v-if="!pending">
            <v-row justify-start wrap>
              <v-col cols="12" sm="4">
                <v-card flat tile>
                  <v-card-title class="py-2">{{ $t('Provider') }}</v-card-title>
                  <v-card-text v-if="tenant_handle && tenant_handle.id">
                    <address>
                      <template v-if="tenant_handle.organisation">
                        <span class="font-weight-bold">{{ tenant_handle.organisation }}</span>
                        <br />
                      </template>
                      <span v-if="tenant_handle.sex == 'MALE'">Herr</span>
                      <span v-if="tenant_handle.sex == 'FEMALE'">Frau</span>
                      {{ tenant_handle.firstname }} {{ tenant_handle.lastname }}
                      <br />
                      {{ tenant_handle.street }} {{ tenant_handle.number }}
                      <br />
                      {{ tenant_handle.zip }} {{ tenant_handle.city }}
                      <br />
                      {{ tenant_handle.country }}
                      <br />
                      <b>Phone:</b>
                      {{ tenant_handle.phone }}
                      <br />
                      <b>Fax:</b>
                      {{ tenant_handle.fax }}
                      <br />
                      {{ tenant_handle.email }}
                      <br />
                    </address>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12" sm="4">
                <v-card flat tile>
                  <v-card-title class="py-2">{{ $t('Client') }}</v-card-title>
                  <v-card-text v-if="client_handle && client_handle.id">
                    <address>
                      <template v-if="client_handle.organisation">
                        <span class="font-weight-bold">{{ client_handle.organisation }}</span>
                        <br />
                      </template>
                      <span v-if="client_handle.sex == 'MALE'">Herr</span>
                      <span v-if="client_handle.sex == 'FEMALE'">Frau</span>
                      {{ client_handle.firstname }} {{ client_handle.lastname }}
                      <br />
                      {{ client_handle.street }} {{ client_handle.number }}
                      <br />
                      {{ client_handle.zip }} {{ client_handle.city }}
                      <br />
                      {{ client_handle.country }}
                      <br />
                      <b>Phone:</b>
                      {{ client_handle.phone }}
                      <br />
                      <b>Fax:</b>
                      {{ client_handle.fax }}
                      <br />
                      {{ client_handle.email }}
                      <br />
                    </address>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12" sm="4">
                <v-card flat tile>
                  <v-card-title class="py-2">{{ $t('Infos') }}</v-card-title>
                  <v-card-text>
                    <p>
                      <b>Start Date:</b>
                      <br />
                      <span>{{ subscription.startdate | moment('DD.MM.YYYY') }}</span>
                      <br />
                    </p>
                    <p>
                      <b>Next Renew:</b>
                      <br />
                      <span>{{ subscription.nextrenew | moment('DD.MM.YYYY') }}</span>
                      <br />
                    </p>
                    <p>
                      <b>Period:</b>
                      <br />
                      <span>{{ subscription.period }}</span>
                      <br />
                    </p>
                    <p>
                      <b>Cancelation Period:</b>
                      <br />
                      <span>{{ subscription.cancelationperiod }}</span>
                      <br />
                    </p>
                    <p>
                      <b>Account:</b>
                      <br />
                      <span v-if="subscription.client">
                        <router-link :to="{ name: 'clients-item', params: { id: subscription.client.id } }">
                          {{ subscription.client.customer }}
                        </router-link>
                      </span>
                    </p>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row justify-start>
              <v-col cols="12">
                <v-card flat tile>
                  <v-card-title class="py-2">{{ $t('Positions') }}</v-card-title>
                  <v-card-text>
                    <v-data-table
                      :headers="headers"
                      :items="subscription.subscription_products"
                      :loading="pending"
                      :hide-default-footer="subscription.subscription_products.length <= 5"
                    >
                      <template #[`item.discount`]="{ item }">
                        {{ item.discount }} {{ item.discount_type === 'percent' ? '%' : '€' }}
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </AppCard>
      </v-col>

      <v-col v-if="subscription.subscription_transactions.length >= 1" cols="12">
        <AppCard :loading="pending.getSubscription" :title="$t('Transactions')">
          <v-list dense>
            <template v-for="transaction in subscription.subscription_transactions">
              <v-list-item :key="`transaction-${transaction.id}`" class="px-0">
                <div class="d-flex justify-space-between w-100">
                  <span>Ausgeführt am: {{ $moment(transaction.created).format('DD.MM.YYYY') }}</span>
                  <v-btn :to="{ name: 'invoices-item', params: { id: transaction.invoice_id } }" icon>
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </div>
              </v-list-item>
            </template>
          </v-list>
        </AppCard>
      </v-col>
    </v-row>
  </ItemWrapper>
</template>

<script>
import { computed, defineComponent, onUnmounted, ref } from '@vue/composition-api'

import AppCard from '@/components/UI/AppCard'
import ItemWrapper from '@/layouts/ItemWrapper'

export default defineComponent({
  name: 'ViewSubscription',
  components: {
    AppCard,
    ItemWrapper,
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // vm.prevRoute = from.fullPath === '/' ? vm.prevRoute : from.fullPath
    })
  },
  setup(props, { root: { $store, $router } }) {
    const prevRoute = ref('/Subscriptions')
    const headers = [
      { text: 'Name', value: 'name' },
      { text: 'Description', value: 'description' },
      { text: 'Price', value: 'price' },
      { text: 'Discount', value: 'discount' },
      { text: 'Tax', value: 'tax' },
    ]

    // store
    const pending = computed(_ => $store.state.subscription.pending)
    const subscription = computed(_ => $store.state.subscription.subscription)
    const getSubscription = _ => $store.dispatch('subscription/getSubscription')
    const clearSubscription = _ => $store.dispatch('subscription/clearSubscription')

    getSubscription()
      .then(r => r)
      .catch(error => {
        if (error.response.status === 404) {
          $router.push({ name: 'notFound' })
        }
      })

    const tenant_handle = computed(_ => subscription.value.tenant?.billing_handle)
    const client_handle = computed(_ => subscription.value.client?.billing_handle)

    onUnmounted(_ => {
      clearSubscription()
    })

    return {
      prevRoute,
      headers,
      pending,
      subscription,
      tenant_handle,
      client_handle,
    }
  },
}) //
</script>
